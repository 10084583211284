import debounce from "lodash/debounce";

class MobileWindowHeight {
  constructor() {
    this.nav = document.getElementById("menu-nav");
    this.splash = document.getElementById("splash");
    this.setNavHeight();
    this.setSplashHeight();
    this.events();
  }

  events() {
    window.addEventListener(
      "resize",
      debounce(() => {
        this.setNavHeight();
      }, 333)
    );
  }

  setSplashHeight() {
    this.splash.style.height = `${window.innerHeight}px`;
  }

  setNavHeight() {
    if (!(window.innerWidth >= 768)) {
      this.nav.style.height = `${window.innerHeight}px`;
    } else {
      this.nav.style.height = "auto";
    }
  }
}

export default MobileWindowHeight;
