import "./styles.css";
import throttle from "lodash/throttle";
import MobileMenu from "./js-modules/MobileMenu";
import RevealOnScroll from "./js-modules/RevealOnScroll";
import MobileWindowHeight from "./js-modules/MobileWindowHeight";
import BackToTop from "./js-modules/BackToTop";
/**
$(function () {
  // Get the form into a variable.
  const form = $("#shingen-reservation");

  // Get the messages div into variable.
  const formMessages = $("#form-messages");

  // Set up an event listener for the contact form.
  $(form).submit(function (e) {
    // Stop the browser from submitting the form.
    e.preventDefault();

    if ($("input#website").val().length != 0) {
      return false;
    }
    // Set the variables
    const email = $('#shingen-reservation input[name="email"]').val();
    const name = $('#shingen-reservation input[name="name"]').val();
    const phone = $('#shingen-reservation input[name="phoneNumber"]').val();
    const pax = $('#shingen-reservation input[name="pax"]').val();
    const date = $('#shingen-reservation input[name="date"]').val();
    const hours = $('#shingen-reservation select[name="hours"]').val();
    const minutes = $('#shingen-reservation select[name="minutes"]').val();
    const amPm = $('#shingen-reservation select[name="amPm"]').val();

    // If not a bot then check to make sure all inputs are filled
    if (name && email && phone && pax && date && hours && minutes && amPm) {
      // Create the validate email against regex function
      function validateEmail(email) {
        const filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

        if (filter.test(email)) {
          return true;
        }

        return false;
      }

      // Call the validate email function
      if (validateEmail(email)) {
        // testing - date and time values returned are in different formats.  they need to be
        // formatted correctly for the javascript date functions to work properly

        console.log(`2a. Date: ${date}`);
        // chrome, firefox - MM-DD-YYYY
        // safari mac - YYYY/MM/DD
        // safari ios - MM-DD-YYYY

        // Commented out below until I can find a proper x-browser fix for the Date() object
        // var dateCorrected = date.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3, $1, $2")

        // use below until I find a proper x-browser Date() object fix
        const dateCorrected = date
          .replace(/(\d{4})\-(\d\d)\-(\d\d)/, "$2-$3-$1")
          .replace(/\//g, "-");
        console.log(`2a. Date Corrected: ${dateCorrected}`);

        const dateSplit = dateCorrected.split("-");
        switch (dateSplit[0]) {
          case "01":
            month = "January";
            break;
          case "02":
            month = "February";
            break;
          case "03":
            month = "March";
            break;
          case "04":
            month = "April";
            break;
          case "05":
            month = "May";
            break;
          case "06":
            month = "June";
            break;
          case "07":
            month = "July";
            break;
          case "08":
            month = "August";
            break;
          case "09":
            month = "September";
            break;
          case "10":
            month = "October";
            break;
          case "11":
            month = "November";
            break;
          case "12":
            month = "December";
        }

        const dateFormatted = `${month} ${dateSplit[1]}, ${dateSplit[2]} (${dateCorrected})`;
        console.log(dateFormatted);

        const time = `${hours}:${minutes} ${amPm}`;
        console.log(`2b. Time: ${time}`);
        // chrome, firefox - MM-DD-YYYY
        // safari mac - YYYY/MM/DD
        // safari ios - MM-DD-YYYY

        // need to convert to a dateTime string that will be able to be passed into to our
        // new Date() object below.  each broswer is different, so we need to standardize this
        // Commented out below until I can find a proper x-browser fix for the Date() object
        // if ( amPm == "PM" ) {
        //	var hoursCorrected = parseInt(hours) + 12
        // } else {
        //	var hoursCorrected = hours
        // }

        // Commented out below until I can find a proper x-browser fix for the Date() object
        // var dateTime = dateCorrected + ", " + hoursCorrected + ", " + minutes
        // console.log ("3. The string that will be passed into the Date object and parser: " + dateTime)

        // once a standard CORRECT string of arguments is created, we can use all the functions below

        // Create a new temporary date object
        // Commented out below until I can find a proper x-browser fix for the Date() object
        // var tempDate = new Date(dateTime);

        // testing - recommended way
        // var parseTempDate = Date.parse(dateTime)
        // var parseTempDateObject = new Date(parseTempDate)

        // testing
        // console.log("4a. using Date object: " + tempDate)
        // console.log("4b. using Parse function: " + parseTempDate + " then placing into Date object: " + parseTempDateObject)

        // Set the options for the Date and Time functions
        // Commented out below until I can find a proper x-browser fix for the Date() object
        // var dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        // var timeOptions = { hour: '2-digit', minute: '2-digit', hour12: 'true' };

        // Separate the date and time into new variables using the functions
        // Commented out below until I can find a proper x-browser fix for the Date() object
        // var newDate = tempDate.toLocaleDateString( 'en-US', dateOptions );
        // var newTime = tempDate.toLocaleTimeString( 'en-US', timeOptions );

        // testing
        // console.log( "5a. new TEMP date after toLocaleDateString is: " + newDate)
        // console.log( "5b. new TEMP time after toLocaleDateString is: " + newTime )

        // Format the separated date and time into a string that can be passed to our php file

        // Commented out below until I can find a proper x-browser fix for the Date() object
        // var newResTime = encodeURI("&formattedDate=" + newDate + "&formattedTime=" + newTime);

        // use below var for now, until a proper x-browser fix for Date() object is found
        const newResTime = encodeURI(
          `&formattedDate=${dateFormatted}&formattedTime=${time}`
        );

        // testing
        console.log(`6. Time sent to php: ${newResTime}`);

        // Serialize the form data.
        let formData = $(form).serialize();

        // Add the string that we created above to our form data string
        formData += newResTime;

        // Submit the form using AJAX.
        $.ajax({
          type: "POST",
          url: $(form).attr("action"),
          data: formData,
        })

          .done(function (response) {
            // Success message.
            successMessage(response);

            // Reset the form
            $("#shingen-reservation")[0].reset();
          })

          .fail(function (data) {
            // Set the message text.
            if (data.responseText !== "") {
              // Error message.
              errorMessage(
                `<p class="section-subtext">${data.responseText}</p>`
              );
            } else {
              // Error message.
              errorMessage(
                '<p class="section-subtext">There was an error submitting your reservation, please try again.</p>'
              );
            }
          });

        // If email is not valid, display a message to the user
      } else {
        // Error message.
        errorMessage(
          '<p class="section-subtext">The email you entered is invalid. Please enter a valid email address before submitting the reservation.</p><p class="section-subtext">入力されたメールアドレスは使えません。もう一度、確認してメールアドレスを入力してください。</p>'
        );
      }

      // If not all inputs are filled, display a message to the user
    } else {
      // Error message.
      errorMessage(
        '<p class="section-subtext">Please fill in all required fields before submitting your reservation.</p><p class="section-subtext">必要な箇所を全部入力しお送りください。</p>'
      );
    }

    function errorMessage(message) {
      $(formMessages).removeClass("success");
      $(formMessages).addClass("error");
      $(formMessages).html(message);
    }

    function successMessage(message) {
      $(formMessages).removeClass("error");
      $(formMessages).addClass("success");
      $(formMessages).html(message);
    }
  });
});
*/

new MobileMenu();
new RevealOnScroll();
new MobileWindowHeight();
new BackToTop();

/**
 ** smooth scroll - new (css tricks)
 ** - when using with wordpress, use "jQuery(document).ready(function ($) {"
 ** - when using on non-wp and jQuery cdn use "$(document).ready(function {"
 */
jQuery(document).ready(function ($) {
  // console.log("jQuery smooth scroll script run...");

  /**
   ** e1 - if using a fixed header get the height of the fixed header when the document loads,
   ** and place into a variable for later, if not using, then set to "0"
   */
  let offset = 0;
  // console.log("Header height on document ready: ", offset);

  /**
   ** e1 - update the height of the fixed header when all the css has loaded,
   ** but if not using a fixed header, then comment out the below function
   */
  $(window).on("load", function () {
    offset = 0;
    // console.log("Header height on page load: ", offset);
  });

  // Select all links with hashes &
  // Remove links that don't actually link to anything
  $('a[href*="#"]')
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, "") ==
          this.pathname.replace(/^\//, "") &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        let target = $(this.hash);
        target = target.length ? target : $(`[name=${this.hash.slice(1)}]`);
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          // e1 - added var since we are offsetting the target to allow for fixed header
          const targetOffset = target.offset().top - offset;

          $("html, body").animate(
            {
              // e1 - changed to include the var we set above
              scrollTop: targetOffset,
              // below is the original code
              // scrollTop: target.offset().top,
            },
            700,
            function () {
              // Callback after animation
              // Must change focus!
              var target = $(target);
              target.focus();

              if (target.is(":focus")) {
                // Checking if the target was focused
                return false;
              }
              target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable

              // e1 - below is the problem code.  setting the focus again, will cause a jump
              // after the animation.  Added "{ preventScroll: true }" in the focus() method
              // to fix the jump
              target.focus({ preventScroll: true }); // Set focus again
            }
          );
        }
      }
    });
});

/**
 ** ON RELOAD of page stop the onscroll from firing by rescrolling
 ** to the top of the page when the page is unloaded, so reloading
 ** the page will display the page from the top and not at the last
 ** scrolled to section
 */
window.addEventListener("unload", function () {
  window.scrollTo(0, 0);
});

if (module.hot) {
  module.hot.accept();
}
