import throttle from "lodash/throttle";

class BackToTop {
  constructor() {
    this.backToTop = document.getElementById("back-to-top");
    this.scrollThrottle = throttle(this.addRemoveScrolled, 200).bind(this);
    this.events();
  }

  events() {
    window.addEventListener("scroll", this.scrollThrottle);
  }

  addRemoveScrolled() {
    this.scrolled = window.pageYOffset;
    if (this.scrolled > 100) {
      this.backToTop.classList.add("back-to-top__button--scrolled");
    } else {
      this.backToTop.classList.remove("back-to-top__button--scrolled");
    }
  }
}

export default BackToTop;
