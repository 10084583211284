class MobileMenu {
  constructor() {
    this.mobileMenuButtonContainer = document.querySelector(
      "#mobileMenuButtonContainer"
    );
    this.mobileMenuButton = document.querySelector("#mobileMenuButton");
    // this.mobileMenuContainer = document.getElementById(
    //   "menu-container-for-mobile"
    // );
    this.menuContainer = document.getElementById("menu-nav");
    this.menu = document.getElementById("top-menu");
    this.links = document.querySelectorAll(".nav-link");
    this.body = document.getElementsByTagName("body");
    this.events();
  }

  events() {
    this.mobileMenuButtonContainer.addEventListener("click", () =>
      this.toggleTheMenu()
    );
    this.links.forEach((link) => {
      link.addEventListener("click", () => this.removeOpenMenuClasses());
    });
  }

  /* below method will toggle classes when the mobile menu icon is clicked */
  toggleTheMenu() {
    this.mobileMenuButton.classList.toggle("navbar-menu__lines--clicked");
    // this.mobileMenuContainer.classList.toggle(
    //   "navbar-menu__container-mobile--visible"
    // );
    this.menuContainer.classList.toggle("navbar__container--menu-open");
    this.menu.classList.toggle("navbar-nav--menu-open");
    this.body[0].classList.toggle("menu-open");
  }

  /* below method will make sure to reset the menu if any link is clicked */
  removeOpenMenuClasses() {
    this.mobileMenuButton.classList.remove("navbar-menu__lines--clicked");
    // this.mobileMenuContainer.classList.remove(
    //   "navbar-menu__container-mobile--visible"
    // );
    this.menuContainer.classList.remove("navbar__container--menu-open");
    this.menu.classList.remove("navbar-nav--menu-open");
    this.body[0].classList.toggle("menu-open");
  }
}

export default MobileMenu;
